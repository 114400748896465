import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import moment from "moment";
import Select from "react-select";
import InvoiceViewModal from "../components/InvoiceViewModal";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
function SiteConclusion() {
  let columns = [
    {
      name: "Date",
      code: "quote_date",
      format: (value) =>
        moment(selectedRow?.quote?.quote_date).format("DD MMM YYYY"),
    },

    {
      name: "Vendor Name",
      code: "vendor",
      format: (value) =>
        selectedRow?.quote?.vendor != null
          ? selectedRow?.quote?.vendor["name"]
          : "",
    },
    {
      name: "Vendor Code",
      code: "vendor",
      format: (value) =>
        selectedRow?.quote?.vendor != null
          ? selectedRow?.quote?.vendor["code"]
          : "",
    },
    // {
    //   name: "Invoice No",
    //   code: "purchase",
    //   format: (value) => {
    //     if (value != null && value["inv_no"]) {
    //       if (value["inv_file"]) {
    //         return (
    //           <span>
    //             {value["inv_no"]}

    //             <button
    //               onClick={() =>
    //                 handleshowInvoice({
    //                   inv_file: value["inv_file"],
    //                   inv_no: value["inv_no"],
    //                 })
    //               }
    //               type="button"
    //               className="button "
    //             >
    //               <svg className="icon fs-22">
    //                 <use href="#icon_eye"></use>
    //               </svg>
    //             </button>
    //           </span>
    //         );
    //       } else {
    //         return value["inv_no"];
    //       }
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      name: "Purchase By",
      code: "employee",
      format: (value) =>
        (selectedRow?.quote?.emp_code || "") +
        "-" +
        (selectedRow?.quote?.employee?.name || ""),
    },
    {
      name: "Quote Id",
      code: "id",
    },
    {
      name: "Purchase Item",
      code: "item",
      format: (value) => (value != null ? value["item_name"] : ""),
    },
    {
      name: "Purchase Qty",
      code: "qty",
    },
    {
      name: "Verified Qty",
      code: "verify_qty",
    },
    {
      name: "Discrepancy Qty",
      code: "discrepancy_qty",
    },
    {
      name: "Return Qty",
      code: "return_qty",
    },
    {
      name: "Adjust Qty",
      code: "adjust_qty",
    },
    {
      name: "Adjust Site",
      code: "adjustsite",
      format: (value) => {
        if (value != null) {
          return value["code"] + "-" + value["name"];
        } else {
          return "";
        }
      },
    },
    {
      name: "Creator Code",
      code: "emp_code",
      format: (value) => selectedRow?.quote?.emp_code,
    },
    {
      name: "Verifier Code",
      code: "verify_by_user_code",
    },
  ];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  function handleshowInvoice(data) {
    setShowInvoice(data);
  }
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedUser, setSelectedUser] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [userList, setUserList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  async function getRows(
    selectedUser,
    selectedSite,
    selectedFromDate,
    selectedToDate
  ) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendorquote`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          user_code: selectedUser?.value || null,
          // site_code: selectedSite?.value || null,
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          // action_type: 2,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
          conclusion: true,
        },
      });

      setRows(response?.data?.data || []);
      if (response?.data?.data?.length) {
        setSelectedRow({
          value: response?.data?.data[0]?.id,
          label:
            response?.data?.data[0]?.id +
            ")-" +
            response?.data?.data[0]?.vendor?.name +
            "-" +
            response?.data?.data[0]?.vendor?.code +
            "-" +
            response?.data?.data[0]?.quote_date,
          quote: response?.data?.data[0],
        });
      } else {
        setSelectedRow(null);
      }
      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function printOrExportData(isPrint, selectedSite, selectedDate) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/purchasediscrepancy`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          user_code: selectedUser?.value || null,
          site_code: selectedSite?.value || null,
          date: selectedDate,
          action_type: 2,
          paginate: false,
        },
      });

      let tableData = response?.data?.data || [];

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table-wrapper {
                width: 100%;
                border-collapse: collapse;
              }
              .table-wrapper th, .table-wrapper td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
              }
              .table-wrapper th {
                background-color: #f2f2f2;
              }
              @media print {
                @page {
                  size: A4 landscape;
                  margin: 15mm;
                }
              }
            </style>
          </head>
          <body>
            <h1>
              Home Expenses Conclusion 
            </h1>
            <table class="table-wrapper">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  ${columns.map((column) => `<th>${column.name}</th>`).join("")}
                </tr>
              </thead>
              <tbody>
             
                               ${tableData
                                 .map((item, index) => {
                                   return `<tr>
                                    <td>${index + 1}</td>
                                    ${columns
                                      .map((column) => {
                                        return `<td>${
                                          column.format
                                            ? column.format(item[column.code])
                                            : item[column.code]
                                        }</td>`;
                                      })
                                      .join("")}
                                  </tr>`;
                                 })
                                 .join("")}

              </tbody>
            </table>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = window.close;
              };
            </script>
          </body>
        </html>
      `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
          };
          columns.forEach((column) => {
            data[column.name] = column.format
              ? column.format(item[column.code])
              : item[column.code];
          });

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `purchase_conclusion.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        params: {
          ledger_master: type,
        },
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data || [];
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  // async function handleSaveReturn() {
  //   setIsSaving(true);
  //   try {
  //     const token = localStorage.getItem("him_user_token");
  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/purchasereturn`,
  //       method: "POST",
  //       headers: {
  //         jwt_token: token,
  //       },
  //       data: {
  //         site_code: selectedSite?.value || null,
  //         date: selectedDate,
  //         data: rows?.map((row) => {
  //           return {
  //             site_code: row?.site_code,
  //             purchase_id: row?.purchase_id,
  //             date: row?.date,
  //             id: row?.id,
  //             item_id: row?.item_id,
  //             tax_per_unit: row?.site_item?.gst_percent,
  //             amount_per_unit: row?.amount_per_unit,
  //             adjust_qty: row?.adjust_qty,
  //             return_qty: row?.return_qty,
  //           };
  //         }),
  //       },
  //     });

  //     if (response?.status === 200) {
  //       setshowSuccess(true);
  //       setSuccessMessagePopup(
  //         response?.data?.msg || "Data saved successfully"
  //       );
  //     } else {
  //       setShowErrModal(true);
  //       setError({ title: "Error", err: response?.data?.message });
  //     }
  //   } catch (error) {
  //     setShowErrModal(true);
  //     setError({ title: "Error", err: error?.message });
  //     if (error?.response?.status == 403) {
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   } finally {
  //     setIsSaving(false);
  //   }
  // }

  useEffect(() => {
    getRows(selectedUser, selectedSite, selectedFromDate, selectedToDate);
  }, [page, limit]);
  useEffect(() => {
    async function fetchData() {
      const users = await getContractors("Employee");
      const sites = await getContractors("Office");
      setUserList(users);
      setSiteList(sites);
    }
    fetchData();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Site Conclusion </h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose User
              </label>

              <Select
                // className="input-box"
                value={selectedUser}
                onChange={(option) => {
                  setSelectedUser(option);
                  if (page == 0) {
                    getRows(
                      option,
                      selectedSite,
                      selectedFromDate,
                      selectedToDate
                    );
                  } else {
                    setPage(0);
                  }
                }}
                options={userList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            {/* <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose Site
              </label>

              <Select
                // className="input-box"
                value={selectedSite}
                onChange={(option) => {
                  setSelectedSite(option);
                  if (page == 0) {
                    getRows(selectedUser, option, selectedDate);
                  } else {
                    setPage(0);
                  }
                }}
                options={siteList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div> */}
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              {/* <input
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  if (page == 0) {
                    getRows(selectedUser, selectedSite, e.target.value);
                  } else {
                    setPage(0);
                  }
                }}
                type="date"
                className="input-box gray"
              /> */}
              <RangePicker
                className="input-box gray"
                value={[selectedFromDate, selectedToDate]}
                format={"DD MMM YYYY"}
                onChange={(dates) => {
                  setSelectedFromDate(dates?.length ? dates[0] : "");
                  setSelectedToDate(dates?.length ? dates[1] : "");
                  if (page == 0) {
                    getRows(
                      selectedUser,
                      selectedSite,
                      dates?.length ? dates[0] : "",
                      dates?.length ? dates[1] : ""
                    );
                  } else {
                    setPage(0);
                  }
                }}
              />
            </div>
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose Quote
              </label>

              <Select
                // className="input-box"
                value={selectedRow}
                onChange={setSelectedRow}
                options={rows.map((user) => ({
                  value: user?.id,
                  label:
                    user?.id +
                    ")-" +
                    user?.vendor?.name +
                    "-" +
                    user?.vendor?.code +
                    "-" +
                    user?.quote_date,
                  quote: user,
                }))}
                // placeholder="Select To Code"
                isSearchable
              />
            </div>
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div> */}
          </div>
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={() =>
                printOrExportData(false, selectedSite, selectedFromDate)
              }
              disabled={isGettingRows || isExporting}
            >
              {isExporting ? <Loader /> : "Export"}
            </button>
          </div>
        </div>
        {/* <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div
            style={{
              visibility: "hidden",
            }}
          >
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              // onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div className="tbl-scroller">
          {!isGettingRows && selectedRow?.quote?.quote_item?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  {columns.map((column) => (
                    <th key={column.code}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {selectedRow?.quote?.quote_item?.map((row, index) => (
                  <>
                    <tr key={row?.id}>
                      <td>{parseInt(page) * limit + index + 1}</td>
                      {columns.map((column) => (
                        <td key={column.code}>
                          {column.format
                            ? column.format(row[column.code])
                            : row[column.code]}
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {/* {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )} */}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={2}>2</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <InvoiceViewModal
        showInvoice={showInvoice}
        handleClose={() => setShowInvoice(false)}
      />
    </>
  );
}

export default SiteConclusion;
